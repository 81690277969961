import React, { useState, useEffect } from 'react'
import CountDownHelper from '../otros/CountDownHelper'

export default function CountDown({date}) {
    
    const calculateTimeLeft = () => {
        const now = new Date().getTime();
        const distance = date.getTime() - now;
        if (distance <= 0) {
          return {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
            finished: true
          };
        } else {
          return {
            days: Math.floor(distance / (1000 * 60 * 60 * 24)).toString().padStart(2, "0"),
            hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString().padStart(2, "0"),
            minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, "0"),
            seconds: Math.floor((distance % (1000 * 60)) / 1000).toString().padStart(2, "0"),
            finished: false
          };
        }
      };
    
      const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    
      useEffect(() => {
        const timer = setInterval(() => {
          setTimeLeft(calculateTimeLeft());
        }, 1000);
        return () => clearInterval(timer);
      }, [date]);

    return (
        <div className='flex flex-row gap-x-1 font-semibold uppercase text-primary w-full justify-center py-5'>
            <CountDownHelper time={timeLeft.days} dateType={"dias"} />
            <span aria-hidden="true" class="mt-1 text-xl">:</span>
            <CountDownHelper time={timeLeft.hours} dateType={"horas"} />
            <span aria-hidden="true" class="mt-1 text-xl">:</span>
            <CountDownHelper time={timeLeft.minutes} dateType={"minutos"} />
            <span aria-hidden="true" class="mt-1 text-xl">:</span>
            <CountDownHelper time={timeLeft.seconds} dateType={"segundos"} />
        </div>
    )
}
