import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation, useParams } from 'react-router-dom';

import { Toaster } from 'sonner'

import { Footer, Header, Sponsors } from "./components";
import { Inicio, Jugadores, PageNotFound, Partidos, Partido, Login, Comunicado } from "./pages";

import { Analytics } from "@vercel/analytics/react"
import Jugador from "./pages/Jugador";
import Galeria from "./pages/Galeria";

import { useStateContext } from "./functions/ContextProvider";
import EditarComunicado from "./admin/EditarComunicado";
import Perfil from "./admin/Perfil";
import Store from "./pages/Store";

function App() {

  const [viewSelect, setViewSelect] = useState("Aldin FC")
  const location = useLocation();

  const { user, setUser } = useStateContext();

  const setView = (view) => {
    setViewSelect(view)
    window.document.title = `Aldin FC | ${view}`
  }

  useEffect(() => {

    window.document.title = "Aldin FC"

    const params = new URLSearchParams(location.search);
    const tokenParam = params.get('token');
    
    if (tokenParam != null) {
      localStorage.setItem('token', tokenParam);
    }

  }, []); // El array vacío asegura que el efecto solo se ejecute una vez

  return (
    <>
      <Toaster richColors />
      <Header viewSelect={viewSelect} setView={setView} />
      <main className="bg-body dark:bg-body_dark text-black dark:text-white md:mb-[69px] mb-[89px]">
        <Routes>
          <Route path="" element={<Inicio/>} />
          <Route path="/jugadores" element={<Jugadores/>} />
          <Route path="/partidos" element={<Partidos/>} />
          <Route path="/partidos/:id" element={<Partido/>} />
          <Route path="/partidos/*" element={<PageNotFound />} />
          <Route path="/jugadores/*" element={<PageNotFound />} />
          <Route path="/jugadores/:id" element={<Jugador />} />
          <Route path="/comunicados/:id" element={<Comunicado />} />
          <Route path="/login" element={<Login />} />
          <Route path="/galeria" element={<Galeria />} />
          <Route path="/store" element={<Store />} />
          <Route path="/*" element={<PageNotFound />} />

          {
            user.id != 0 ? (
              <>
              <Route path="/admin/comunicados" element={<PageNotFound />} />
              <Route path="/admin/comunicados/:id" element={<EditarComunicado />} />
              <Route path="/perfil" element={<Perfil />} />
              </>
            ) : null
          }

        </Routes>
        <Sponsors />
      </main>
      <Footer />
      <Analytics />
    </>
  );
}

export default App;
