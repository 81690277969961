import React, { useEffect } from 'react'
import { Comunicado, CountDown, Embed } from '../components'
import { Link } from 'react-router-dom'

import { getInicio } from '../functions/funcions'

import { Partido } from '../components'
import Palmares from '../components/Palmares'

import Marquee from "react-fast-marquee";
import NumberTicker from '../components/NumberTicker'

export default function Inicio() {

  const [inicio, setInicio] = React.useState({})

  useEffect(() => {
    getInicio()
      .then((data) => {
        setInicio(data)
        window.document.title = "Aldin FC"
      })
  }, [])

  function shuffle(arr) {
    var i,
      j,
      temp;
    for (i = arr.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      temp = arr[i];
      arr[i] = arr[j];
      arr[j] = temp;
    }
    return arr;
  };

  return (
    <div className='my-5'>
      <meta name="description" content="Aldin FC es un club de fútbol. Fundado en 2023." />
      {/* Twitter Meta */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@aldin_f.c" />
      <meta name="twitter:title" content="Aldin FC es un club de fútbol. Fundado en 2023." />
      <meta name="twitter:description" content="Aldin FC es un club de fútbol. Fundado en 2023." />
      <meta name="twitter:image" content="https://server.pabloeguilaz.es/aldinfc/static/escudo.png" />
      {/* Open Graph Meta */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Aldin FC es un club de fútbol. Fundado en 2023." />
      <meta property="og:description" content="Aldin FC es un club de fútbol. Fundado en 2023." />
      <meta property="og:image" content="https://server.pabloeguilaz.es/aldinfc/static/escudo.png" />
      <meta property="og:url" content="https://aldinfc.es/inicio" />
      <meta property="og:site_name" content="Aldin FC" />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:alt" content="Aldin FC Escudo" />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:see_also" content="https://aldinfc.es" />
      <meta property="og:see_also" content="https://server.pabloeguilaz.es/aldinfc/static/escudo.png" />

      <link rel="canonical" href="https://aldinfc.es" />

      <h1 className='text-3xl font-bold text-center sr-only'>Inicio</h1>

      {
        inicio.countdown ? (
          <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-[20px] flex flex-col gap-[100px]'>
            <section className='gap-[40px]'>
              <h2 className='text-2xl font-bold pb-3 w-full text-center'>{inicio.countdown.title}</h2>
              <CountDown date={new Date(inicio.countdown.date)} />
            </section>
          </div>
        ) : (<></>)
      }


      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-[20px] flex flex-col gap-[100px]'>

        {
          inicio.partidos ? (
            <section className='flex flex-col md:flex-row justify-between gap-[40px]'>
              {
                inicio.partidos.ultimo ? (
                  <div className='w-full md:w-1/2'>
                    <h2 className='text-2xl font-bold pb-3'>Último partido</h2>
                    <Link to={"/partidos/" + inicio.partidos.ultimo.id}>
                      <Partido partido={inicio.partidos.ultimo} />
                    </Link>
                  </div>
                ) : (<></>)
              }
              {
                inicio.partidos.proximo ? (
                  <div className='w-full md:w-1/2'>
                    <h2 className='text-2xl font-bold pb-3'>Próximo partido</h2>
                    <Link to={"/partidos/" + inicio.partidos.proximo.id}>
                      <Partido partido={inicio.partidos.proximo} />
                    </Link>
                  </div>
                ) : (<></>)
              }
            </section>
          ) : (<></>)
        }

      </div>

      {/*
        <video className='w-full h-auto md:h-[600px] rounded-lg' autoPlay muted loop preload="auto" >
          <source src="https://server.pabloeguilaz.es/aldinfc/static/historias/1.mp4" type="video/mp4" />
        </video>
        */}

      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-[20px] flex flex-col gap-[100px]'>

        <section>
          {
            inicio.ultima_noticia ? (
              <Link to={"comunicados/" + inicio.ultima_noticia.id} class="flex flex-col items-center rounded-lg md:flex-row group " >
                <img class="object-cover w-full md:w-2/3 rounded-lg group-hover:scale-105 transition-all h-[400px]" src={inicio.ultima_noticia.image} alt="" />
                <div className='py-[30px] md:p-[30px] w-full md:w-1/3'>
                  <h2 className='text-3xl font-bold group-hover:text-primary transition-all duration-200'>{inicio.ultima_noticia.title}</h2>
                </div>
              </Link>
            ) : (<><p></p></>)
          }
        </section>

      </div>

      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-[20px] flex flex-col gap-[100px]'>

        {
          inicio.palmares ? (
            <section>
              {
                inicio.palmares.length > 0 ? (<h2 className='text-2xl font-bold pb-3'>Palmarés</h2>) : (<></>)
              }
              <Palmares palmares={inicio.palmares} />
            </section>
          ) : (<> </>)
        }

      </div>

      {

        inicio.marquee ? (

          <section className='flex flex-col gap-2 py-3'>
            <Marquee pauseOnHover >
              {
                shuffle(inicio.marquee).map((marquee, index) => {
                  return (
                    <figure class="mx-2 bg-box dark:bg-box_dark relative w-64 cursor-pointer overflow-hidden rounded-xl border p-4">
                      <div class="flex flex-row items-center gap-2">
                        <img class="rounded-full" width="32" height="32" alt="" src="https://avatar.vercel.sh/jack" />
                        <div class="flex flex-col">
                          <figcaption class="text-sm font-medium dark:text-white">{marquee.nombre}</figcaption>
                          <p class="text-xs font-medium dark:text-white/40">{marquee.red_social}</p>
                        </div>
                      </div>
                      <blockquote class="mt-2 text-sm">{marquee.texto}</blockquote>
                    </figure>
                  )
                })
              }
            </Marquee>
            <Marquee pauseOnHover direction='right'>
              {

                // Desordenar el array de marquesinas

                shuffle(inicio.marquee).map((marquee, index) => {
                  return (
                    <figure class="mx-2 bg-box dark:bg-box_dark relative w-64 cursor-pointer overflow-hidden rounded-xl border p-4">
                      <div class="flex flex-row items-center gap-2">
                        <img class="rounded-full" width="32" height="32" alt="" src="https://avatar.vercel.sh/jack" />
                        <div class="flex flex-col">
                          <figcaption class="text-sm font-medium dark:text-white">{marquee.nombre}</figcaption>
                          <p class="text-xs font-medium dark:text-white/40">{marquee.red_social}</p>
                        </div>
                      </div>
                      <blockquote class="mt-2 text-sm">{marquee.texto}</blockquote>
                    </figure>
                  )
                })
              }
            </Marquee>
          </section>

        ) : (<></>)

      }

      
      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-[20px] flex flex-col gap-[100px]'>

        {
          inicio.contenido ? (
            <section>
              {
                inicio.contenido.length > 0 ? (<h2 className='text-2xl font-bold pb-3'></h2>) : (<></>)
              }
              {
                inicio.contenido.map((contenido, index) => {
                  if (contenido.type == "embed") {
                    return (
                      <Embed key={index} embedCode={contenido.embedCode} />
                    )
                  }  
                })
              }
            </section>
          ) : (<> </>)
        }

      </div>

      {/*
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-[20px] flex flex-col gap-[100px]">
        <div class="">
          <dl class="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
            <div class="mx-auto flex max-w-xs flex-col gap-y-4">
              <dt class="text-base leading-7 text-gray-600">Partidos</dt>
              <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl"><NumberTicker value={10} direction='up' delay={0} className='whitespace-pre-wrap text-8xl font-medium tracking-tighter text-black dark:text-white' /></dd>
            </div>
            <div class="mx-auto flex max-w-xs flex-col gap-y-4">
              <dt class="text-base leading-7 text-gray-600">Partidos</dt>
              <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl"><NumberTicker value={10} direction='up' delay={0} className='whitespace-pre-wrap text-8xl font-medium tracking-tighter text-black dark:text-white' /></dd>
            </div>
            <div class="mx-auto flex max-w-xs flex-col gap-y-4">
              <dt class="text-base leading-7 text-gray-600">Partidos</dt>
              <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl"><NumberTicker value={10} direction='up' delay={0} className='whitespace-pre-wrap text-8xl font-medium tracking-tighter text-black dark:text-white' /></dd>
            </div>
          </dl>
        </div>
      </div>
      */}

      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-[20px] flex flex-col gap-[100px]'>

        {
          inicio.ultimas_noticias ? (
            <section>
              <h2 className='text-2xl font-bold pb-3'>Últimas noticias</h2>
              <div className='grid gap-6 grid-cols-[repeat(auto-fill,minmax(250px,1fr))]'>
                {
                  inicio.ultimas_noticias.map((noticia, index) => {
                    return (
                      <Comunicado key={index} comunicado={noticia} />
                    )
                  })
                }
              </div>

            </section>
          ) : (<></>)
        }

        {/*
          inicio.map((e) => {
            switch (e.type) {
              case 'CountDown':
                return <CountDown key={e.id} date={new Date(e.date)} />
              case 'comunicado':
                return <Comunicado key={e.id} comunicado={e} />
              case 'InstagramEmbed':
                return <div className=''><InstagramEmbed key={e.id} embedCode={e.embedCode} /></div>
              case "partido":
                return <Partido key={e.id} partido={e} />
              default:
                return <div key={e.id}>No se ha encontrado el componente {e.type}</div>
            }
          })
          */
        }
      </div>

    </div>
  )
}
