import React, { useEffect } from 'react';

export default function Embed({embedCode}) {

  useEffect(() => {
    // Asegúrate de que el script de Instagram se cargue y ejecute después de montar el componente
    const script = document.createElement('script');
    script.async = true;
    script.src = '//www.instagram.com/embed.js';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      className='flex align-center justify-center p-2'
      dangerouslySetInnerHTML={{
        __html: `${embedCode}`
      }}
    />
  );
};

