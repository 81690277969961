import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { convertirFecha } from '../functions/funcions'

import { useStateContext } from '../functions/ContextProvider'

export default function Comunicado() {

    // Obtener id del comunicado
    const id = useParams().id

    const { user, setUser } = useStateContext()

    const [comunicado, setComunicado] = useState({ content: [{ content: '' }] })

    useEffect(() => {
        // Llamar a la API para obtener el comunicado
        fetch(`https://server.pabloeguilaz.es/aldinfc/api/comunicados/${id}`)
            .then(response => response.json())
            .then(data => {
                setComunicado(data)
                document.title = data.title + ' | Aldin FC'
            })
    }, [])

    return (
        <div className='mx-auto max-w-[820px] px-4 sm:px-6 lg:px-8 py-[40px] '>
            <meta name="description" content={comunicado.subtitle} />
            {/* OG Meta */}
            <meta property="og:type" content="website" />
            <meta property="og:title" content={comunicado.subtitle} />
            <meta property="og:description" content={comunicado.subtitle} />
            <meta property="og:image" content={comunicado.image} />
            <meta property="og:url" content={`https://aldinfc.es/comunicados/${id}`} />
            <meta property="og:site_name" content="Aldin FC" />
            <meta property="og:locale" content="es_ES" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:image:alt" content={comunicado.title} />
            <meta property="og:image:type" content="image/png" />

            {/* Twitter Meta */}
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@aldin_f.c" />
            <meta name="twitter:title" content={comunicado.subtitle} />
            <meta name="twitter:description" content={comunicado.subtitle} />
            <meta name="twitter:image" content={comunicado.image} />
            <meta name="twitter:url" content={`https://aldinfc.es/comunicados/${id}`} />
            <meta name="twitter:creator" content="Aldin FC" />
            <meta name="twitter:domain" content="aldinfc.es" />
            
            {
                user.id != 0 ? (
                    <div className='flex justify-end'>
                        <Link to={'/admin/comunicados/' + comunicado.id} className='bg-primary text-white px-3 py-2 rounded-lg ml-3'>Editar</Link>
                    </div>
                ) : null
            }
            <h1 className='text-[32px] md:text-[48px] font-bold py-5 leading-[35px] md:leading-[52px]'>{comunicado.title}</h1>
            {/* <h2 className='text-3xl py-5'>{comunicado.subtitle}</h2> */}
            <img className='rounded-lg mt-5 max-h-[500px] object-cover w-full' src={comunicado.image} alt={comunicado.title} />
            <p className='pb-5 pt-1 text-sm text-slate-500'>{convertirFecha(comunicado.date)}</p>
            <article className=''>
                {
                    comunicado.content.map((paragraph, index) => {
                        return (
                            <p key={index} className='pt-3 text-justify' dangerouslySetInnerHTML={{
                                __html: `${paragraph.content}`
                            }} />
                        )
                    })
                }
            </article>
        </div>
    )
}
