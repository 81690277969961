import React, { useState, useEffect } from 'react'
import Partido from '../components/Partido';
import { Link } from 'react-router-dom';
import { createClient } from "@libsql/client";
import { toast } from 'sonner';

export default function Partidos() {

    const [PartidosData, setPartidosData] = useState([])

    useEffect(() => {
        fetch('https://server.pabloeguilaz.es/aldinfc/api/partidos/grouped')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                console.log(data);
                setPartidosData(data);
                window.document.title = "Aldin FC | Resultados"
            })
            .catch((error) => {
                toast.error('Error al cargar los resultados');
            });

        window.document.title = "Aldin FC | Resultados"
    }, []);

    return (
        <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-[20px]'>
            <meta name="description" content="Partidos del Aldin FC" />
            {/* Twitter Meta */}
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@aldin_f.c" />
            <meta name="twitter:title" content="Partidos del Aldin FC" />
            <meta name="twitter:description" content="Partidos del Aldin FC" />
            <meta name="twitter:image" content="https://server.pabloeguilaz.es/aldinfc/escudo.png" />
            {/* Open Graph Meta */}
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Partidos del Aldin FC" />
            <meta property="og:description" content="Partidos del Aldin FC" />
            <meta property="og:image" content="https://server.pabloeguilaz.es/aldinfc/escudo.png" />
            <meta property="og:url" content="https://aldinfc.es/partidos" />
            <meta property="og:site_name" content="Aldin FC" />
            <meta property="og:locale" content="es_ES" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:image:alt" content="Aldin FC Escudo" />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:see_also" content="https://aldinfc.es" />
            <meta property="og:see_also" content="https://server.pabloeguilaz.es/aldinfc/escudo.png" />

            <link rel="canonical" href="https://aldinfc.es/partidos" />

            <h1 className='text-3xl font-bold text-center sr-only'>Partidos</h1>
            {
                PartidosData.map((temporadas, index) => {
                    return (
                        <div class="flex items-center justify-between w-full">
                            <div class="space-y-1 w-full">
                                <h2 class="text-2xl font-semibold tracking-tight">{temporadas.temporada}</h2>
                                {
                                    temporadas.competiciones.map((competicion, index) => {
                                        return (
                                            <div key={competicion.competicion} className='py-2'>
                                                <p class="text-sm text-muted-foreground">{competicion.competicion}</p>
                                                <section className='grid gap-6 grid-cols-[repeat(auto-fill,minmax(300px,1fr))] w-full py-3'>
                                                    {
                                                        competicion.partidos.map((partido, index) => {
                                                            return (
                                                                <Link to={`/partidos/${partido.id}`} key={index}>
                                                                    <Partido partido={partido} />
                                                                </Link>
                                                            )
                                                        })
                                                    }
                                                </section>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )

                })
            }
            {
                /*
                Object.keys(PartidosData).map(year => (
                    <div class="flex items-center justify-between w-full">
                        <div class="space-y-1 w-full">
                            <h2 class="text-2xl font-semibold tracking-tight">{year}</h2>
                            {
                                Object.keys(PartidosData[year]).map(competicion => (
                                    <div key={competicion} className='py-2'>
                                        <p class="text-sm text-muted-foreground">{competicion}</p>
                                        <section className='grid gap-6 grid-cols-[repeat(auto-fill,minmax(300px,1fr))] w-full py-3'>
                                            {
                                                PartidosData[year][competicion].map((partido, index) => {
                                                    
                                                })
                                            }
                                        </section>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                ))
                */
            }

            <section className='grid gap-6 grid-cols-[repeat(auto-fill,minmax(300px,1fr))]'>

                {/*
                    PartidosData.map((partido, index) => {
                        return (
                            <Link to={`/partidos/${partido.id}`} key={index}>
                                <Partido partido={partido} />
                            </Link>
                        )
                    })
                */}
            </section>
        </div>
    )
}
