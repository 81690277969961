import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

import confetti from 'canvas-confetti';

import { convertirFecha } from '../functions/funcions'

export default function Partido() {

    const [id, setID] = useState(useParams().id)

    const [partido, setPartido] = useState({
        "id": 4,
        "fecha": "2024-08-03 09:00:00",
        "competicion": {
            "id": 1,
            "nombre": ""
        },
        "visitante": {
            "id": 4,
            "nombre": "",
            "escudo": "",
            "goles": 0
        },
        "local": {
            "id": 1,
            "nombre": "",
            "escudo": "",
            "goles": 0
        },
        "stats": [

        ]
    })

    useEffect(() => {
        fetch('https://server.pabloeguilaz.es/aldinfc/api/partidos/' + id)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                console.log(data);
                setPartido(data);
                window.document.title = "Aldin FC | Partido"
                //toast.success('Partidos cargados correctamente');
            })
            .catch((error) => {
                toast.error('Error al cargar los partidos');
            });

        window.document.title = "Aldin FC | Partido"
    }, []);



    function crearConfetti() {

        var duration = 2 * 1000;
        var animationEnd = Date.now() + duration;
        var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

        function randomInRange(min, max) {
            return Math.random() * (max - min) + min;
        }

        var interval = setInterval(function () {
            var timeLeft = animationEnd - Date.now();

            if (timeLeft <= 0) {
                return clearInterval(interval);
            }

            var particleCount = 50 * (timeLeft / duration);
            // since particles fall down, start a bit higher than random
            confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } });
            confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } });
        }, 250);

    }

    useEffect(() => {

        if (partido.local.id == 1) {
            if (partido.local.goles > partido.visitante.goles) {
                crearConfetti();
            }
        } else {
            if (partido.visitante.goles > partido.local.goles) {
                crearConfetti();
            }
        }

    }, [partido]);

    return (
        <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-[20px]'>
            <section className='bg-box dark:bg-box_dark hover:shadow-md p-4 rounded-lg border border-box_border dark:border-box_border_dark text-black dark:text-white shadow dark:shadow-box_border_dark cursor-pointer'>
                <div className='h-[50px] flex items-center justify-between'>
                    <p></p>
                    <p>{partido.competicion.nombre} | {convertirFecha(partido.fecha)}</p>
                    <p></p>
                </div>
                <hr className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'></hr>
                <div className='w-full flex items-center justify-around py-5'>
                    <div>
                        <img src={partido.local.escudo} alt={partido.local.nombre} className='w-[120px]' />
                        <p className='flex items-center justify-center'>{partido.local.nombre}</p>
                    </div>
                    <div>
                        <p className='pr-2 text-3xl font-bold'>{partido.local.goles} - {partido.visitante.goles}</p>
                    </div>
                    <div>
                        <img src={partido.visitante.escudo} alt={partido.visitante.nombre} className='w-[120px]' />
                        <p className='flex items-center justify-center'>{partido.visitante.nombre}</p>
                    </div>
                </div>
            </section>

            <div class="space-y-8 pt-[40px] relative before:absolute before:inset-0 before:ml-5 before:-translate-x-px md:before:mx-auto md:before:translate-x-0 before:h-full before:w-0.5 before:bg-gradient-to-b before:from-transparent before:via-slate-300 before:to-transparent">

                {
                    partido.stats.map((stat, index) => {
                        if (index % 2 == 0) {
                            return (
                                <div class="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active">
                                    
                                </div>
                            )
                        } else {
                            return (
                                <div class="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active">
                                    <div class="flex items-center justify-center w-10 h-10 rounded-full border border-white cursor-pointer bg-slate-300 group-[.is-active]:bg-emerald-500 text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2">
                                        <svg class="fill-current" xmlns="http://www.w3.org/2000/svg" width="12" height="10">
                                            <path fill-rule="nonzero" d="M10.422 1.257 4.655 7.025 2.553 4.923A.916.916 0 0 0 1.257 6.22l2.75 2.75a.916.916 0 0 0 1.296 0l6.415-6.416a.916.916 0 0 0-1.296-1.296Z" />
                                        </svg>
                                    </div>
                                    <div class="w-[calc(100%-4rem)] md:w-[calc(50%-2.5rem)] bg-box dark:bg-box_dark hover:shadow-md p-4 rounded-lg border border-box_border dark:border-box_border_dark text-black dark:text-white dark:shadow-box_border_dark shadow cursor-pointer">
                                        <div class="block lg:flex items-center justify-between lg:space-x-2 mb-1">
                                            <div class="font-bold">{stat.tipo}</div>
                                            <time class="font-caveat font-medium text-indigo-500">{stat.minuto}</time>
                                        </div>
                                        <div class="text-slate-500">{stat.nombre}</div>
                                        <div class="flex justify-start gap-2 pt-[7px] overflow-x-scroll">

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })
                }

            </div>

        </div>
    )
}
